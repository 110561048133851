
/* eslint-disable */
import Vue from "vue";

import Header from "@/components/Header.vue"
import HomeService from '@/services/home.service'
export default Vue.extend({
  name: 'Home',
  components: {
    Header,
  },
  data() {
      return {
        claroOscuro:String(localStorage.getItem('claroOscuro')),
        originBot:String(localStorage.getItem('originBot')),
        CallCenterHoraInicio:String(localStorage.getItem('CallCenterHoraInicio')),
        CallCenterHoraFin:String(localStorage.getItem('CallCenterHoraFin')),
      }
  },
  created(){
    HomeService.CargaInicial().then((resp) => {
        this.claroOscuro = resp.data.claroOscuro;
        this.originBot = resp.data.originBot;  
      }).catch((error) =>{
        console.error("Error al realizar la carga inicial IpBloqueada",error);
      }
    );
  },
  methods:{
    cerrar(){
      let url: any = location; let new_window: any = open(url, '_self');  new_window.close();
    }
  }
})
